function dateHandler(date: Date, options?: { separator?: string }) {
  const separator = options?.separator || ' '
  const locale = 'en'

  const today = new Date()
  today.setHours(0)
  today.setMinutes(0)
  today.setSeconds(0, 0)

  const yesterday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1
  )

  const getDateString = (option: Record<string, string>) =>
    new Intl.DateTimeFormat(locale, option).format(date)
  const year = getDateString({ year: 'numeric' })
  const month = getDateString({ month: 'long' })
  const day = getDateString({ day: '2-digit' })

  const addSeparator = (a: string, b: string, c: string) =>
    '' + a + separator + b + separator + c
  const dmy = addSeparator(day, month, year)
  const ymd = addSeparator(year, month, day)
  const mdy = addSeparator(month, day, year)

  function getTimeLapse(defaultValue: string) {
    const activityTime = date.getTime()
    if (activityTime >= today.getTime()) {
      return 'Today'
    } else if (activityTime >= yesterday.getTime()) {
      return 'Yesterday'
    } else {
      return defaultValue
    }
  }

  return {
    dmy,
    timeLapseDmy: getTimeLapse(dmy),
    ymd,
    timeLapseYmd: getTimeLapse(ymd),
    mdy,
    timeLapseMdy: getTimeLapse(mdy),
    year,
    numericMonth: date.getMonth(),
    month,
    day,
    timeString: date.toLocaleTimeString(locale, {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    })
  }
}

export default dateHandler
