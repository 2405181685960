import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { transparentize } from 'polished'

export const Container = styled.div`
  & > *:first-child {
    box-sizing: border-box;
    padding: ${primitives.layout.size0}px;

    & > *:first-child {
      box-sizing: border-box;
      height: 56px;
    }
  }
`

export const Content = styled.div`
  box-sizing: border-box;
  height: 390px;
  max-height: 390px;
  overflow-y: auto;

  // Hide scrollbar on IE and Edge
  -ms-overflow-style: none;
  // Hide scrollbar on Firefox
  scrollbar-width: none;
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }
`
export const CardHeader = styled.div`
  height: 35px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.1);
  padding: 10px ${primitives.layout.size5}px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: var(--light-foreground, #212121);

  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4};
  font-style: normal;
  font-weight: 400;
  line-height: ${primitives.typography.lineHeight4};
`

export const HeaderLeftArea = styled.div``

export const HeaderRightArea = styled.div``

export const SelectWrapper = styled.div`
  width: 160px;
`
export const ContentStyles = styled.div`
  padding: ${primitives.layout.size0}px ${primitives.layout.size6}px
    ${primitives.layout.size3}px ${primitives.layout.size6}px;
`

export const ListItemContentInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 2px;
`

export const ListItemContentTitle = styled.h3`
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
`

export const ListItemContentDatetimeArea = styled.div`
  color: #737373;
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  display: flex;
  gap: ${primitives.layout.size1}px;
`

export const ListItemContentDate = styled.span``

export const ListItemContentTime = styled.span``

export const ListItemContentSubtitleArea = styled.div`
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${primitives.layout.size1}px;
`

export const ListItemContentSubtitle = styled.span`
  text-transform: none !important;
`

export const ListItemContentReference = styled.span`
  color: ${primitives.color.gray12};
`

export const ListItemLeftArea = styled.div`
  display: flex;
  gap: 10px;
`

export const AvatarAreaContent = styled.div`
  position: relative;
  top: -15px;
`

export const Avatar = styled.div`
  display: flex;
  width: 36px;
  background: ${(props) => transparentize(0.9, props.color)};
  height: 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  svg {
    color: ${(props) => props.color};
  }
`

export const Badge = styled.div<{
  enable?: boolean
}>`
  background: ${(props) => (props.enable ? primitives.color.red7 : null)};
  height: ${primitives.layout.size1}px;
  width: ${primitives.layout.size1}px;
  border-radius: 50%;
`

export const NoItemsText = styled.p`
  color: #737373;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(390px - 56px);
  color: #404040;
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  svg {
    background: rgba(33, 33, 33, 0.1);
    border-radius: 50%;
    height: 36px;
    width: 36px;
    padding: 6px;
    margin-bottom: ${primitives.layout.size2}px;
  }
`
export const TruncatedContainer = styled.div<{
  isExpanded: boolean
  isTruncated: boolean
  maxHeight: number
  lineHeight: number
}>`
  max-height: ${(props) =>
    props.isExpanded
      ? 'none'
      : props.isTruncated
        ? `${props.maxHeight}px`
        : 'none'};
  overflow: hidden;
  line-height: ${(props) => `${props.lineHeight}px`};
`
