import React from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisWebHttpClient } from '@jarvis/web-http'
import {
  IconInfo,
  IconWarningAlt,
  IconXCircle,
  IconCheckmarkCircle
} from '@veneer/core'
import dateHandler from '../../utils/dateHandler'
import * as T from './types'

/* istanbul ignore next */
export default async function getActivities(stack, authProvider, locale) {
  const urlEnv = (() => {
    switch (stack) {
      case Stack.prod:
        return ''
      case Stack.stage:
        return 'stage-'
      default:
        return 'pie-'
    }
  })()

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/activitylog/v1/messages`

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  })

  const activityResponse: T.getActivitiesReturnTypeNew = await httpClient
    .get({
      params: {
        locale: locale,
        smart: 'true'
      }
    })
    .then((r) => r.data)

  const activities: T.getActivitiesReturnType = {
    count: activityResponse?.total.toString() || '0',
    items: []
  }

  activityResponse?.items?.forEach((activity) => {
    const { id, receivedAt, body, messageLevel, acknowledgedAt, title, link } =
      activity || {}

    const activityDate = receivedAt
      ? dateHandler(new Date(receivedAt), { separator: ' ' })
      : undefined

    const subtitle = body.split('|')
    const newsubtitle = `${subtitle[0]}\xa0\xa0|\xa0\xa0${subtitle[1]}`

    let icon: React.FC
    let color: string
    let buttonLabel: string

    if (messageLevel === 'error') {
      icon = IconXCircle
      color = '#be1313'
      buttonLabel = 'View Details'
    } else if (messageLevel === 'warning') {
      icon = IconWarningAlt
      color = '#d06702'
      buttonLabel = 'More'
    } else if (messageLevel === 'info') {
      icon = IconInfo
      color = '#737373'
      buttonLabel = 'Dismiss'
    } else if (messageLevel === 'success') {
      icon = IconCheckmarkCircle
      color = '#1c7a17'
      buttonLabel = 'Dismiss'
    }

    if (messageLevel === 'warning' || messageLevel === 'error') {
      activities.items.push({
        id: id,
        icon,
        color,
        showBadge: !acknowledgedAt,
        subTitle: '',
        reference: newsubtitle,
        time: activityDate?.timeString,
        date: activityDate?.timeLapseDmy,
        title: title,
        buttonLabel: buttonLabel,
        href: link
      })
    }
  })
  activities.count = activities.items.length.toString()
  return activities
}
