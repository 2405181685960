import { useState, useEffect } from 'react'
import getActivities from '../../../services/getActivities'
import { getActivitiesReturnType } from '../../../services/getActivities/types'

export type useActivitiesStateManagerType = {
  stack
  baseAuthProvider
  refresh: boolean
  locale: string
}

const useActivitiesStateManager = ({
  stack,
  baseAuthProvider,
  refresh,
  locale
}: useActivitiesStateManagerType) => {
  const [isLoading, setIsLoading] = useState(true)
  const [activities, setActivities] = useState<
    getActivitiesReturnType | undefined
  >()
  const thereAreItemstoShow = activities?.items?.length > 0

  useEffect(() => {
    setIsLoading(true)
    getActivities(stack, baseAuthProvider, locale)
      .then(setActivities)
      .catch(() => setActivities(undefined))
      .finally(() => {
        setIsLoading(false)
      })
  }, [stack, refresh, baseAuthProvider, locale])
  return {
    isLoading,
    activities,
    thereAreItemstoShow
  }
}

export default useActivitiesStateManager
