import React from 'react'
import { ProgressIndicator } from '@veneer/core'

import { Container } from './styles'
import testid from '../../data-testid'

interface LoaderProps {
  fullScreen?: boolean
}

export const Loader: React.FC<LoaderProps> = ({ fullScreen }) => (
  <Container fullScreen={fullScreen}>
    <ProgressIndicator data-testid={testid('loader')} />
  </Container>
)
