let localprops = {
  language: 'en',
  country: 'US',
  mock: false,
  events: null,
  properties: {
    useOrgedAuthProvider: null
  }
}

export const setProps = (props) => {
  localprops = {
    ...localprops,
    ...props
  }
}

export const getProps = () => {
  return localprops
}
