import styled from 'styled-components'

interface ContainerProps {
  fullScreen: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(390px - 56px);
  width: 100%;
`

export default Container
