import React, { useState } from 'react'
import {
  Select,
  List,
  ListItem,
  ThemeProvider,
  Button,
  IconInfo,
  ContextualMenu,
  IconWarningAlt,
  IconChevronRight,
  Card
} from '@veneer/core'
import testId from '../../data-testid'
import { Loader } from '../Loader'
import useActivitiesStateManager from './hooks/useActivitiesStateManager'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import sendAcknowledgement from '../../services/sendAcknowledgement'
import sendDismiss from '../../services/sendDismiss'
import { setProps } from '../../utils/commonMethods'
import * as S from './styles'
import TruncatedText from './TruncatedText'

export const NotificationWidget = (props) => {
  setProps(props)

  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation
  const authProvider = interfaces?.v2?.authProvider || {}
  const stack = interfaces?.v1?.app?.getAuthStack?.()
  const locale = interfaces?.v1?.localization?.locale
  const disableSelect = false
  const [baseAuthProvider] = useState(
    props?.properties?.useOrgedAuthProvider
      ? authProvider.createOrgedAuthProvider()
      : authProvider
  )

  const selectOptions = [
    {
      value: 0,
      since: '30d',
      label: `${t(
        'ecp-notification-widget.header.select.all-time',
        'All time'
      )}`
    },
    {
      value: 1,
      since: '1d',
      label: `${t(
        'ecp-notification-widget.header.select.last-1-day',
        'Last 24 hours'
      )}`
    },
    {
      value: 2,
      since: '7d',
      label: `${t(
        'ecp-notification-widget.header.select.last-7-days',
        'Last 7 days'
      )}`
    },
    {
      value: 3,
      since: '30d',
      label: `${t(
        'ecp-notification-widget.header.select.last-30-days',
        'Last 30 days'
      )}`
    }
  ]

  const contexMenuOptions = [
    {
      value: 'details',
      label: 'View Details'
    },
    {
      value: 'dismiss',
      label: 'Dismiss'
    }
  ]
  const [refresh, setRefresh] = useState(false)
  const [option, setOption] = useState(0)

  const { activities, isLoading, thereAreItemstoShow } =
    useActivitiesStateManager({
      stack,
      baseAuthProvider,
      refresh,
      locale
    })
  const HeaderRightArea = (): JSX.Element => {
    return (
      <ThemeProvider density="high">
        <S.SelectWrapper>
          {disableSelect && (
            <Select
              options={selectOptions}
              id="select-usage"
              value={[selectOptions?.[option]?.value || 0]}
              clearIcon={false}
              onChange={({ value }) =>
                setOption(typeof value === 'number' ? value : 0)
              }
              disabled={true}
            />
          )}
        </S.SelectWrapper>
      </ThemeProvider>
    )
  }

  const AvatarArea = ({ icon, color }): JSX.Element => {
    const Icon = icon || IconInfo

    return (
      <S.AvatarAreaContent>
        <S.Avatar color={color}>
          <Icon
            data-testid={testId('notifications-item-icon')}
            filled
            color={color}
          />
        </S.Avatar>
      </S.AvatarAreaContent>
    )
  }

  const getContent = (
    <S.Content data-testid={testId('widget-card-content')}>
      <S.CardHeader>
        <S.HeaderLeftArea>
          {t('ecp-notification-widget.header.title', 'Notifications')}
          {` (${activities?.count || 0})`}
        </S.HeaderLeftArea>
        <S.HeaderRightArea>{<HeaderRightArea />}</S.HeaderRightArea>
      </S.CardHeader>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          {thereAreItemstoShow ? (
            <S.ContentStyles>
              <List data-testid={testId('notifications-list')}>
                {activities?.items?.map?.((item) => {
                  const subtitles = item?.reference.split('\n')
                  const joinedSubtitles = subtitles.slice(0, -1).join(' ')
                  return (
                    <ListItem
                      key={item?.id}
                      leadingArea={
                        <S.ListItemLeftArea>
                          <S.Badge
                            data-testid={testId('notifications-item-badge')}
                            enable={item?.showBadge}
                          />
                          <AvatarArea
                            data-testid={testId('notifications-item-icon')}
                            icon={item?.icon}
                            color={item?.color}
                          />
                        </S.ListItemLeftArea>
                      }
                      centerArea={
                        <S.ListItemContentInfoArea>
                          <div>
                            <div>
                              <S.ListItemContentTitle>
                                {item?.title}
                              </S.ListItemContentTitle>
                            </div>
                            {subtitles.length > 0 && (
                              <S.ListItemContentSubtitleArea>
                                <S.ListItemContentReference>
                                  {subtitles[subtitles.length - 1] || '-'}
                                </S.ListItemContentReference>
                              </S.ListItemContentSubtitleArea>
                            )}
                            <TruncatedText
                              item={{ id: item?.id, message: joinedSubtitles }}
                              t={t}
                            />
                          </div>
                          {(item?.date || item?.time) && (
                            <S.ListItemContentDatetimeArea>
                              {item?.date && (
                                <S.ListItemContentDate>
                                  {item?.date}
                                </S.ListItemContentDate>
                              )}
                              {item?.date && item?.time && '\xa0|\xa0'}
                              {item?.time && (
                                <S.ListItemContentTime>
                                  {item?.time}
                                </S.ListItemContentTime>
                              )}
                            </S.ListItemContentDatetimeArea>
                          )}
                        </S.ListItemContentInfoArea>
                      }
                      trailingArea={
                        item?.href ? (
                          item?.buttonLabel !== 'View Details' ? (
                            <ContextualMenu
                              options={contexMenuOptions}
                              placement="trailing-start"
                              onClick={(e, option) => {
                                switch (option.value) {
                                  case 'details':
                                    if (item?.href) {
                                      if (item.href.startsWith('http')) {
                                        window.open(item.href)
                                      } else {
                                        navigation?.push?.(item?.href)
                                        sendAcknowledgement(
                                          props.stack,
                                          baseAuthProvider,
                                          item?.id
                                        )
                                      }
                                    }
                                    break
                                  case 'dismiss':
                                    sendDismiss(
                                      props.stack,
                                      baseAuthProvider,
                                      item?.id
                                    ).finally(() => setRefresh(!refresh))
                                    break
                                }
                              }}
                            >
                              <Button
                                small={true}
                                aria-label="Actions"
                                appearance="ghost"
                                trailingIcon={<IconChevronRight />}
                              >
                                {item?.buttonLabel}
                              </Button>
                            </ContextualMenu>
                          ) : (
                            <Button
                              small={true}
                              aria-label="Actions"
                              appearance="ghost"
                              trailingIcon={<IconChevronRight />}
                              onClick={() => {
                                navigation?.push?.(item?.href)
                                sendAcknowledgement(
                                  props.stack,
                                  baseAuthProvider,
                                  item?.id
                                )
                              }}
                            >
                              {item?.buttonLabel}
                            </Button>
                          )
                        ) : (
                          <Button
                            small={true}
                            aria-label="Actions"
                            appearance="ghost"
                            onClick={() => {
                              sendDismiss(
                                props.stack,
                                baseAuthProvider,
                                item?.id
                              ).finally(() => setRefresh(!refresh))
                            }}
                          >
                            {t('ecp-notification-widget.dismiss', 'Dismiss')}
                          </Button>
                        )
                      }
                    />
                  )
                })}
              </List>
            </S.ContentStyles>
          ) : (
            <S.NoItemsText>
              <IconWarningAlt />
              {t('ecp-notification-widget.no-items', 'No items')}
            </S.NoItemsText>
          )}
        </>
      )}
    </S.Content>
  )

  return (
    <ThemeProvider density="high">
      <Card
        data-testid={testId('widget-card')}
        content={getContent}
      ></Card>
    </ThemeProvider>
  )
}

export default NotificationWidget
