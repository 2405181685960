import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider, JarvisWebHttpClient } from '@jarvis/web-http'

export default async function sendAcknowledgement(
  stack: Stack,
  authProvider: JarvisAuthProvider,
  id?: string
) {
  const urlEnv = (() => {
    switch (stack) {
      case Stack.prod:
        return ''
      case Stack.stage:
        return 'stage-'
      default:
        return 'pie-'
    }
  })()

  const baseUrl = `https://${urlEnv}us1.api.ws-hp.com/activitylog/v1/messages/${id}/acknowledge`

  const httpClient = new JarvisWebHttpClient({
    defaultAuthProvider: authProvider,
    defaultBaseURL: baseUrl
  })

  const acknowledgementResponse = await httpClient.post({})

  return acknowledgementResponse
}
