import React, { useEffect, useState, useRef } from 'react'
import { HyperlinkText } from '@jarvis/react-portal-addons'
import { TruncatedContainer } from './styles'

const MAX_LINES = 2
const lineHeight = 20
const maxHeight = MAX_LINES * lineHeight

const TruncatedText = ({ item, t }) => {
  const containerRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [expandedItems, setExpandedItems] = useState({})
  const isExpanded = expandedItems[item.id] || false
  const toggleExpanded = () => {
    setExpandedItems({ ...expandedItems, [item.id]: !expandedItems[item.id] })
  }

  useEffect(() => {
    if (containerRef.current && containerRef.current.offsetHeight > maxHeight) {
      setIsTruncated(true)
    }
  }, [])

  return (
    <>
      <TruncatedContainer
        ref={containerRef}
        isExpanded={isExpanded}
        isTruncated={isTruncated}
        maxHeight={maxHeight}
        lineHeight={lineHeight}
      >
        {item.message}
      </TruncatedContainer>
      {isTruncated && (
        <HyperlinkText
          data-testid="hyperlink-text"
          onClick={toggleExpanded}
        >
          {isExpanded
            ? `${t('ecp-notification-widget.seeLess', 'See Less')}`
            : `${t('ecp-notification-widget.seeMore', 'See More')}`}
        </HyperlinkText>
      )}
    </>
  )
}

export default TruncatedText
